/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

:root {
  --background-color: #fff;
  --alt-background-color: #18191A;
  --text-color: #18191A;
  --prompt-text-color: #18191A;
  --divider-color: #242526;
  --alt-text-color: #d3d5dd;
  --placeholder-color: #575757;
  --border-color: #18191A;
  --alt-border-color: #d3d5dd;
  --alt-border-color-rgba1: rgba(211, 213, 221, 0.05);
  --alt-border-color-rgba2: rgba(211, 213, 221, 0.04);
  --ion-toolbar-background: transparent;
  --ion-background: #fff;
  --ion-message-background: #d3d5dd;
  --ion-menu-content-background: #d3d5dd;
  --ion-tertiary: #4C17CA;
  --ion-item-color: #18191A;
  --ion-alert-background: #000000;
  --ion-alert-alt-background: #fff;
  --ion-alert-text-color: #d3d5dd;
  --ion-alert-button-danger-color: #FF5322;
  --ion-alert-button-normal-color: #18191A;
  --ion-alt-color: #d3d5dd;
  --ion-highlight-color: #4C17CA;
  --ion-highlight-text-color: #d3d5dd;
  --ion-background-highlight: #4c17c973;
  --ion-background-dark: #d3d5dd;
  --ion-background-code: #f5f5f5;
  --img-filter: none;

  --ion-color-primary-lighter: #6c5ce7;
  --ion-color-success-lighter: #00b894;
  --ion-color-transparent-light: rgb(0, 0, 0, 0.05);
  --ion-color-transparent-medium: rgb(0, 0, 0, 0.25);
  --ion-color-transparent-dark: rgb(0, 0, 0, 0.5);
  --ion-color-burger-menu: var(--ion-color-primary);

  --ion-background-shade: rgb(0, 0, 0, 0.025);

  --chat-toolbar-height: 57px;
  --chat-footer-height: 62px;
  --chat-left-pane-width: 300px;
  --chat-main-pane-max-width: 800px;
  --chat-max-textarea-height: 160px;
}

body.dark-mode {
  --background-color: #18191A;
  --alt-background-color: #d3d5dd;
  --text-color: #d3d5dd;
  --prompt-text-color: #d3d5dd;
  --divider-color: #ffffff;
  --alt-text-color: #18191A;
  --placeholder-color: #575757;
  --border-color: #d3d5dd;
  --alt-border-color: #343434;
  --alt-border-color-rgba1: rgba(52, 52, 52, 0.05);
  --alt-border-color-rgba2: rgba(52, 52, 52, 0.04);
  --ion-toolbar-background: #18191A;
  --ion-background: #242526;
  --ion-message-background: #242526;
  --ion-menu-content-background: #18191A;
  --ion-tertiary: #D2EB12;
  --ion-item-color: #d3d5dd;
  --ion-alert-background: #000000;
  --ion-alert-alt-background: #000000;
  --ion-alert-text-color: #d3d5dd;
  --ion-alert-button-danger-color: #FF5322;
  --ion-alert-button-normal-color: #d3d5dd;
  --ion-alt-color: #18191A;
  --ion-background-dark: #000;
  --ion-background-code: #040506;
  --img-filter: brightness(0) invert(1);

  --ion-color-transparent-light: rgb(255, 255, 255, 0.05);
  --ion-color-transparent-medium: rgb(255, 255, 255, 0.25);
  --ion-color-transparent-dark: rgb(255, 255, 255, 0.5);
  --ion-color-burger-menu: #fff;

  --ion-background-shade: var(--background-color);
}

ion-toolbar {
  --background: var(--ion-toolbar-background);
  --color: var(--text-color);
}

.main-content {
  --background: linear-gradient(180deg, #FF5322 0%, #4C17CA 61.13%);
}

@font-face {
  font-family: 'Trois Mille Regular';
  src: url('/assets/font/TroisMilleRegular-24.otf');
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/font/Inter.ttf');
}

label, .label-stacked.sc-ion-label-md-h {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 2 !important;

  &.textarea-wrapper {
    font-size: 1rem;
    line-height: 1.25rem !important;
  }

}

.ion-accordion-toggle-icon {
  color: var(--text-color);
}

ion-select::part(label) {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.alert {
  --background: var(--ion-alert-alt-background);
  --color: var(--text-color);
  --ion-text-color: var(--text-color);

  .alert-title {
    color: var(--text-color);
  }

  .button-danger {
    color: var(--ion-alert-button-danger-color);
  }

  .button-normal {
    color: var(--ion-alert-button-normal-color);
  }
}

.chat-alert {
  --ion-text-color: var(--text-color);
  --background: var(--ion-alert-alt-background);
}

.links line {
  stroke: var(--text-color);
  stroke-opacity: 0.6;
}

.links line[stroke-width='3'] {
  stroke: #145b28;
}

.nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
}

.nodes circle.group-2 {
  stroke: #FF5322;
}

.licence-modal {
  --width: 100%;
  --height: 100%;
}

.profile-modal {
  --width: 90%;
  --max-width: 500px;
  --height: 90%;
  --max-height: 660px;
}

ion-alert.select-alert {
  --background: var(--ion-alert-background);
  --ion-color-step-850: var(--text-color);
}

.account-switch-popover, .context-switch-popover, .new-block-popover {
  --width: 350px;
}

.log-popover {
  --width: 800px;
}

.select-popover {
  --width: 320px;
}

ion-popover {
  ion-list {
    background: var(--ion-background) !important;

    ion-item {
      --background: var(--ion-background);
      --color: var(--ion-item-color);
      --border-color: var(--ion-item-color);
    }
  }
}

.back-button {
  --color: var(--text-color);
}

.large-modal {
  --height: 100%;
}

.object-storage-new-popover {
  --background: transparent;
  --offset-y: 8px;
}

.ion-tertiary {
  color: var(--ion-tertiary);
}

.spinner-backdrop {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);

  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh);
    margin-top: -26px;
    width: 100%;

    ion-spinner {
      width: 3rem;
      height: 3rem;
      --color: var(--ion-color-danger);
    }

    &.spinner-container--pane {
      // Center in container accounting for left ion-split-pane
      --left-pane-width: 299px;
      width: calc(100vw - var(--left-pane-width));

      @media screen and (max-width: 991px) {
        // Center full width when left pane is hidden
        width: 100%;
      }
    }
  }
}

form, .form-dark {
  margin-top: 1rem;
  margin-bottom: 1rem;

  ion-list {
    &.form-list {
      background: var(--background-color);
      border-color: var(--divider-color);
    }

    ion-item {
      --background: var(--background-color);
      --color: var(--text-color);
      --border-color: var(--divider-color);

    }

    ion-input {
      --border-color: var(--divider-color);
    }

    ion-checkbox {
      --border-color: var(--divider-color);
    }

    ion-range {
      padding: 0 10px;
      --bar-background: rgba(var(--ion-color-secondary-rgb, 56, 128, 255), 0.26);
      --bar-background-active: var(--ion-color-secondary);
    }

    ion-button {
      margin-top: 1rem;
    }

    ion-range::part(label) {
      font-size: 1rem;
      margin-left: -10px;
    }
  }
}

.ion-pane-toast {
  --left-pane-width: 299px;
  width: calc(100vw - var(--left-pane-width)); // Adjust the width of the main pane
  max-width: 80%;
  left: calc(var(--left-pane-width)); // Center it in the main content pane
  transform: translateX(0);
}

.ion-pane-toast-top {
  @extend .ion-pane-toast;
  --ion-toolbar-height: 57px;
  top: var(--ion-toolbar-height);
}

@media (max-width: 991px) {
  .ion-pane-toast {
    width: 100%;
    max-width: 100%;
    left: 0;
    transform: translateX(0);
  }
}

[hidden] {
  display: none !important;
}

ion-textarea.chat .native-textarea {
  caret-color: var(--text-color) !important;
}
