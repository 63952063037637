// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #4C17CA;
  --ion-color-primary-rgb: 76, 23, 202;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4C17CA;
  --ion-color-primary-tint: #4C17CA;

  /** secondary **/
  --ion-color-secondary: #FF5322;
  --ion-color-secondary-rgb: 255, 83, 34;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #FF5322;
  --ion-color-secondary-tint: #FF5322;

  /** tertiary **/
  --ion-color-tertiary: #D2EB12;
  --ion-color-tertiary-rgb: 210, 235, 18;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #D2EB12;
  --ion-color-tertiary-tint: #D2EB12;

  /** success **/
  --ion-color-success: #145b28;
  --ion-color-success-rgb: 20, 91, 40;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #145b28;
  --ion-color-success-tint: #145b28;

  /** warning **/
  --ion-color-warning: #D2EB12;
  --ion-color-warning-rgb: 210, 235, 18;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #D2EB12;
  --ion-color-warning-tint: #D2EB12;

  /** danger **/
  --ion-color-danger: #FF5322;
  --ion-color-danger-rgb: 255, 83, 34;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #FF5322;
  --ion-color-danger-tint: #FF5322;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #000000;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #D3D5DD;
  --ion-color-light-rgb: 211, 213, 221;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #D3D5DD;
  --ion-color-light-tint: #D3D5DD;

  --ion-text-color: #ffffff;

  --ion-font-family: 'Inter';
}
